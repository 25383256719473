import { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import SelectedImage from "./Selectedimage";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { apiService } from "../../services/ApiService"; 
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
 

const properties = {
    duration: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    Infinity :false,
    indicators: false,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 10,
                slidesToScroll: 10
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 6,
                slidesToScroll: 6
            }
        }
    ]
  };

class Clients extends Component {
    state = {
        tabSelected: -1,
    };

    
    render() {
        const { data } = this.props; 
        return (
            <div className="Gallary" id="clients">
                <Container>
                <div className="Gallary1">
                    {/* Clients Header Start */}
                    <div className="GallaryTitle">
                        {/* Clients Title Start */}
                        <div className="GallaryTitleWord">
                            <span></span>
                            <h5>Clients</h5>
                        </div>
                    {/* Clients Title End */}
                    </div>
                </div>
                <div className="slide-container">
                    <Slide {...properties}>
                    {data.map((slideImage, index)=> (
                        <div className="each-slide" key={index}>
                        <div className="SlideImageStyle"  style={{'backgroundImage': `url(${apiService.imageLink + slideImage.logo})`}}>
                        </div>
                        </div>
                    ))} 
                    </Slide>
                </div>
                {/* Gallary List End  */}
                </Container>
            </div>
        );
    } 
}

export default Clients;
